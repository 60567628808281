import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Head } from "./Head"
import { Breadcrumbs } from "./Breadcrumbs"

interface LayoutProps {
  title: string
  description?: string
  imageUrl?: string
  breadcrumbs?: {
    name: string
    slug?: string
  }[]
}

export const Layout: React.FC<LayoutProps> = ({ children, ...props }) => {
  const {
    site: {
      siteMetadata: { locale },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          locale
        }
      }
    }
  `)

  return (
    <>
      <Head lang={locale}>
        <title>{props.title}</title>
        <meta name="title" content={props.title} />
        <meta name="description" content={props.description} />
        {props.imageUrl && <meta property="og:title" content={props.title} />}
        {props.imageUrl && <meta property="og:description" content={props.description} />}{" "}
        {props.imageUrl && <meta property="og:image" content={props.imageUrl} />}
        {props.imageUrl && <meta name="twitter:title" content={props.title} />}
        {props.imageUrl && <meta property="twitter:description" content={props.description} />}
        {props.imageUrl && <meta name="twitter:image" content={props.imageUrl} />}
        {props.imageUrl && <meta name="twitter:card" content={props.imageUrl} />}
      </Head>
      <Breadcrumbs items={props.breadcrumbs} />
      {children}
    </>
  )
}
