import React from "react"
import styled from "@emotion/styled"
import { Link as GatsbyLink } from "gatsby"
import { Helmet } from "react-helmet"
import { BreadcrumbList } from "schema-dts"
import { helmetJsonLdProp } from "react-schemaorg"

interface BreadcrumbsProps {
  items?: {
    name: string
    slug?: string
  }[]
}

const BreadCrumbs = styled.div`
  color: #6d5c5c;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;

  font-family: sans;
  font-size: 0.8rem;

  margin: 0.5rem 1rem;
`

const Seperator = styled.span`
  margin-right: 1rem;
`

const anchorBase = `
margin-right: 1rem;
`

const Anchor = styled(GatsbyLink)<any>`
  ${anchorBase}
  color: #6d5c5c;
  font-size: ${props => props.fontSize || "inherit"};
  text-decoration: ${props => (props.fontSize ? "none" : "underline")};
`

const AnchorLess = styled.div`
  ${anchorBase}
`

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
  if (!items) return null

  return (
    <>
      <Helmet
        script={[
          helmetJsonLdProp<BreadcrumbList>({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: items.map((breadcrumb, index) => {
              return {
                "@type": "ListItem",
                position: index + 1,
                name: breadcrumb.name,
                item: breadcrumb.slug
                  ? `${process.env.GATSBY_DOMAIN}/${breadcrumb.slug}`
                  : undefined,
              }
            }),
          }),
        ]}
      />
      <BreadCrumbs>
        <Anchor fontSize="1.5em" to={`/`}>
          ⌂
        </Anchor>
        <Seperator>{"╤"}</Seperator>
        {items.map((item, i) => (
          <React.Fragment key={item.name}>
            {i > 0 && <Seperator>{"♦"}</Seperator>}
            {item.slug ? (
              <Anchor to={`/${item.slug}/`}>{item.name}</Anchor>
            ) : (
              <AnchorLess>{item.name}</AnchorLess>
            )}
          </React.Fragment>
        ))}
      </BreadCrumbs>
    </>
  )
}
